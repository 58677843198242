@import "typo";
@import "color";
@import "utils";

.card--default {
  @extend .card-job;
}
.card--hover {
  @extend .card-job;
  background-color: $ss-gray-10;
}
.card--active {
  @extend .card-job;
  background: rgba(242, 248, 255, 0.60);

}

.card-job {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 22px 24px 24px;
  cursor: pointer;
  border-bottom: 1px solid $ss-gray-30;

  &:last-of-type {
    border-bottom: none;
  }

  .header {
    .like-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      .score-wrapper {
        @include caption-text-3;
        color: $ss-green-50;
        display: flex;
        align-items: center;
        gap: 6px;
      }
    }
    .title-wrapper {
      @extend .flex-box;
      justify-content: space-between;
      margin-bottom: 6px;

      .title {
        @include h7;
        max-width: 100%;

        .title-text {
          margin-right: 8px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: $ss-gray-100;
        }
        svg {
          flex-shrink: 0;
        }
      }
    }
    .subtitle {
      @include body-text-3;
      color: $ss-gray-100;
      @extend .flex-box;

      .date-deadline-wrapper {
        @extend .flex-box;
        white-space: nowrap;
        .date-deadline {
          color: $ss-gray-90;
        }
        .d-days {
          color: $ss-green-50;
          &.today {
            color: $ss-red-50;
          }
        }
      }
      .position {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .footer {
    @include body-text-3;
    color: $ss-gray-100;
    padding: 0 8px 0 4px;
  }
  .date-wrapper {
    @include caption-text-1;
    color: $ss-gray-60;
  }
}

.card-divided--default {
  @extend .card-job-divided;
}

.card-job-divided {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 20px 20px 20px;
  border: 1px solid $ss-gray-40;
  border-radius: 12px;
  background-color: $ss-white;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;

  .header {
    .like-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }

    .title-wrapper {
      @extend .flex-box;
      justify-content: space-between;
      margin-bottom: 6px;

      .title {
        @include h7;
        color: $ss-black;
        width: 100%;

        .title-text {
          margin-right: 8px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: $ss-gray-100;
        }
        svg {
          flex-shrink: 0;
        }
      }
      .btn-like {
        cursor: pointer;
        margin-top: 4px;
      }
    }
    .subtitle {
      @include body-text-2;
      @extend .flex-box;

      .position {
        @include body-text-3;
        color: $ss-gray-100;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .footer {
    @include body-text-3;
    color: $ss-gray-100;
    padding: 0 8px 0 4px;
  }
  .date-wrapper {
    @include caption-text-1;
    color: $ss-gray-60;
  }
}

.card-job-detail-in-list{
  margin-right: 24px;
  border: 1px solid $ss-gray-30;
  border-radius: 12px;

  .card-job-detail-head {
    padding: 24px 32px;
    margin-bottom: 8px;
    border-bottom: none;

    .head-info-wrapper {
      @extend .flex-box;
      justify-content: space-between;
      margin-bottom: 24px;

      .detail-link-wrapper {
        @extend .flex-box;
        gap: 4px;

        .detail-link {
          @include btn-text-3;
          color: $ss-blue-50;
          text-decoration: none;
        }
      }
      .date-liked {
        @include caption-text-3;
        color: $ss-gray-60;
      }
    }

    .left-right-wrapper {
      display: flex;
      justify-content: space-between;

      .left {
        width: calc(100% - 272px);
        display: flex;
        flex-direction: column;
        gap: 12px;

        .header {
          .title-wrapper {
            .title {
              @extend .flex-box;
              @include h6;
              color: $ss-gray-100;

              &:hover {
                text-decoration: underline;
              }

              .title-text {
                margin-right: 8px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              svg {
                flex-shrink: 0;
              }
            }
          }
        }
        .footer {
          @include body-text-3;
          color: $ss-gray-100;
        }
      }
    }
    .btn-wrapper {
      @extend .flex-box;
      gap: 8px;
      flex-shrink: 0;
    }
    .date-deadline-wrapper {
      margin-top: 16px;
      @include body-text-2;
      @extend .flex-box;
      justify-content: center;
      color: $ss-gray-90;
      padding: 11px;
      background-color: $ss-green-5;


      &.today {
        background-color: $ss-red-5;
      }
      &.closed {
        background-color: $ss-gray-10;
      }

      .date-deadline {
        color: $ss-gray-90;
      }
      .d-days {
        color: $ss-green-50;
        &.today {
          color: $ss-red-50;
        }
      }
    }
  }

  &.in-list {
    border-radius: 12px;
    margin-right: 0;

    .card-job-detail-head {
      .left-right-wrapper {
        .right {
          padding-top: 15px;
          .btn-like {
            margin-left: 6px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .info-section {
    @include h7;
    padding: 32px 0;
  }
  .info-section-sub {
    margin-top: 28px;
    @include subtitle-text-1;
  }

  .info-field {
    margin-top: 16px;
    @include content-text-1;
  }

  .info-block-icon {
    @extend .flex-box;
    padding: 0 24px;

    .content-box {
      @extend .flex-box;
      margin-right: 60px;
      &:last-of-type {
        margin-right: 0;
      }

      .content-with-icon {
        margin-left: 10px;
      }
      .text-1 {
        @include body-text-3;
        color: $ss-gray-80;
      }
      .text-2 {
        @include body-text-2;
        color: $ss-black;
      }
    }
  }

  &.mobile {
    padding: 24px 0;
    margin-right: 0;
    .card-job-detail-head {
      margin-bottom: 0;
      .detail-link-wrapper {
        display: none;
      }
      .left-right-wrapper {
        .left {
          .header {
            .title-wrapper {
              .title {
                @include h6;
              }
            }
          }
        }
      }
    }
  }
}

.card-job-landing {
  padding: 24px 27px;
  border: 1px solid $ss-gray-30;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  cursor: pointer;

  .logo-box {
    @extend .flex-box;
    gap: 9px;

    .logo {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      box-sizing: border-box;
      border: 1px solid $ss-gray-30;
      border-radius: 2px;
    }
    .name {
      @include body-text-4;
      color: $ss-gray-60;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .title {
    @include subtitle-text-1;
    color: $ss-gray-100;
    margin-top: 16px;
    margin-bottom: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .position {
    @include body-text-4;
    color: $ss-gray-100;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .stacks-wrapper {
    @include caption-text-1;
    color: $ss-gray-100;
    margin-top: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;

    .plus-count {
      @include body-text-4;
      color: $ss-gray-80;
    }
  }
}

.card-job-rec {
  @extend .card-job-landing;
  background-color: $ss-white;
  border-radius: 8px;
  width: calc(50% - 8px);
  padding: 0;

  a {
    display: block;
    padding: 12px;
  }

  .logo-box {
    .logo {
      width: 40px;
      height: 40px;
    }
  }
}

.card-company-job {
  @extend .card-job-landing;
  padding: 24px 20px;
  background-color: $ss-white;
  border-radius: 12px;
  width: calc(50% - 12px);

  .title {
    margin-top: 0;
    margin-bottom: 6px;
  }

  a {
    display: block;
  }

  .stacks-wrapper {
    margin-top: 12px;
  }
}

.card-comment {
  .header {
    margin-bottom: 24px;

    .content-box {
      @extend .flex-box;
      .img-thumbnail {
        border-radius: 50%;
      }

      .content-with-img {
        margin-left: 16px;

        .title {
          @include body-text-3;
          color: $ss-gray-100;
          margin-bottom: 4px;
        }
        .level {
          @include caption-text-1;
          color: $ss-gray-60;
        }
      }
    }
  }
  .body {
    @include body-text-2;
    color: $ss-gray-90;
  }
}

.card-company {
  .info-list {
    @include content-text-2;
    color: $ss-gray-90;
    display: flex;
    align-items: baseline;
    margin-bottom: 24px;

    .info-list-label {
      width: 84px;
      flex-shrink: 0;
      margin-right: 16px;
      text-align: left;
      color: $ss-gray-80;
    }

    .info-list-value {
      white-space: pre-wrap;
    }
  }
}

.card-company-mobile {
  .info-list {
    @include content-text-2;
    color: $ss-gray-90;
    margin-bottom: 24px;
    &:last-of-type {
      margin-bottom: 0;
    }

    .info-list-label {
      color: $ss-gray-80;
    }

    .info-list-value {
      white-space: pre-wrap;
    }
  }
}

.detail-box {
  padding: 0 32px;
  border-top: 1px solid $ss-gray-30;
}

@media (max-width: 1079px) {
  .card-company-job {
    width: 100%;
  }

  .card-job-rec {
    width: 100%;
  }
}